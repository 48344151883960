import axios from "axios";
import config from "../../../config.json"; 

/**
 * Получаем данные дешборда по /api/dashboard/data.
 * @param {Object} params - { start, end }
 * @returns {Promise<Object>} JSON-объект с полями:
 *   { requestsData, managerData, overallData, distributionData, durationData, managers }
 */
export const fetchDashboardData = async (params) => {
  // Например, { start: "2025-01-01", end: "2025-02-01" }
  const response = await axios.get(`${config.service}/api/dashboard/data`, {
    params,
    headers: {
      // Если нужно отключить кэш:
      "Cache-Control": "no-cache",
      // Если требуется авторизация:
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    }
  });

  // Если сервер отдаёт 304 — можно вручную обрабатывать, но проще отключить ETag на бэкенде.
  return response.data;
};
