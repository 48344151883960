import { patchRequest } from "../../../utils/requests";
import { message } from "antd";
import config from "../../../config.json";

export const refuseDistribution = async (applicationId) => {
  try {
    const url = `${config.service}/api/distribution/refuse`;
    const body = {
      applicationId,
      state: "NEW",
      status: "NEW",
    };
    await patchRequest(url, body);
    message.success("Заявка отклонена");
  } catch (error) {
    console.error("Ошибка при отклонении заявки:", error);
    message.error("Ошибка при отклонении заявки");
  }
};


