// kepler_front_new\src\pages\NewLead\utils\table.js

import { CloseOutlined } from "@ant-design/icons";
import { Input, InputNumber } from "antd";
import { currencyFormat } from "./price";
import { calculateAssembly } from "./orderAccessories";

/**
 * Проверка, является ли товар "дверью" (по наличию ширины/высоты).
 */
const isDoor = (selectedProduct) => {
  return !!selectedProduct?.width;
};

/**
 * Возвращает массив колонок таблицы (для дверей).
 */
export const getSelectedProductsColumns = () => {
  const columns = [
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      width: 25,
      fixed: "left",
    },
    {
      title: "№",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Наименование товара",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Размеры",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Открывание",
      dataIndex: "opening",
      key: "opening",
    },
    {
      title: "Алюминий",
      dataIndex: "aluminium",
      key: "aluminium",
    },
    {
      title: "Ручка",
      dataIndex: "doorKnob",
      key: "doorKnob",
    },
    {
      title: "Поворотник",
      dataIndex: "latchName",
      key: "latchName",
    },
    {
      title: "Порог",
      dataIndex: "doorstepName",
      key: "doorsterName",
    },
    {
      title: "Сборка",
      dataIndex: "assembly",
      key: "assembly",
    },
    {
      title: "Доп петля",
      dataIndex: "addPetli",
      key: "addPetli",
    },
    {
      title: "Добор",
      dataIndex: "doorPanel",
      key: "doorPanel",
    },
    {
      title: "Кол-во",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Ед. изм.",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Скидка",
      dataIndex: "orderDiscountNode",
      key: "orderDiscountNode",
    },
    {
      title: "Удорожания, %",
      dataIndex: "appreciationPercentage",
      key: "appreciationPercentage",
    },
    {
      title: "Сумма",
      dataIndex: "priceWithDiscount",
      key: "priceWithDiscount",
      render: (_, record) => {
        return <div style={{ inlineSize: 100 }}>{currencyFormat(record?.priceWithDiscount || 0)}</div>;
      },
    },
    {
      title: "Примечаниe",
      dataIndex: "description",
      key: "description",
    },
  ];

  return columns;
};

/**
 * Хелпер: посчитать "финальную" цену конкретного аксессуара, учитывая:
 *  - accessory.price
 *  - accessoryDiscount (accessory.accessoryDiscount)
 *  - accessoryAppreciation (accessory.accessoryAppreciation)
 *  - умножение на количество дверей (amount)
 */
function calcAccessoryFinalPrice(accessory, amount) {
  // 1) Начальная цена аксессуара
  let accPrice = accessory.price || 0;

  // 2) Умножаем на кол-во дверей (если нужно)
  accPrice *= amount;

  // 3) Удорожание аксессуара
  const accAppreciation = accessory.accessoryAppreciation || 0;
  if (accAppreciation > 0) {
    const plusVal = Math.round(accPrice * (accAppreciation / 100));
    accPrice += plusVal;
  }

  // 4) Скидка на аксессуар
  const accDiscount = accessory.accessoryDiscount || 0;
  if (accDiscount > 0) {
    const minusVal = Math.round(accPrice * (accDiscount / 100));
    accPrice -= minusVal;
  }

  return accPrice;
}

/**
 * Формирует "строки" таблицы, которую рендерит SelectedProductsList.
 * Содержит логику разделения скидок:
 *   - doorDiscount / appreciationPercentage влияют ТОЛЬКО на дверь;
 *   - accessoryDiscount / accessoryAppreciation влияют ТОЛЬКО на аксессуар.
 *
 * @param {Array} selectedProducts
 * @param {Function} setSelectedProducts
 * @param {Function} deleteSelectedProduct
 * @param {number} increasePrice – глобальное удорожание контракта, %
 * @returns {Array} dataSource для антовской Table
 */
export const getSelectedProductsDataSource = (
  selectedProducts = [],
  setSelectedProducts = () => {},
  deleteSelectedProduct = () => {},
  increasePrice = 0
) => {
  const dataSource = [];
  let number = 1;

  for (let i = 0; i < selectedProducts.length; i++) {
    const selectedProduct = selectedProducts[i];
    const product = selectedProduct.product || selectedProduct;
    const amount = selectedProduct?.amount || 1;

    // 1) Базовая цена двери
    let doorBasePrice = selectedProduct?.price || product?.price || 0;

    // 2) Если ALUMINIUM = BLACK => можем делать надбавку (пока *1).
    if (selectedProduct.aluminium === "BLACK") {
      doorBasePrice *= 1; 
    }
    if (selectedProduct.aluminium === "GOLD") {
      doorBasePrice *= 1.1;
    }

    // 3) Умножаем на кол-во (amount)
    doorBasePrice *= amount;

    // 4) Если isDoor => возможна сборка.
    let assemblyPrice = 0;
    if (isDoor(selectedProduct) && selectedProduct.assembly) {
      const assemblyObj = calculateAssembly([selectedProduct]);
      assemblyPrice = assemblyObj?.price || 0;
    }

    // Суммируем дверь + сборка (до скидки)
    let doorPriceWithAssembly = doorBasePrice + assemblyPrice;

    // 5) Применяем локальное удорожание двери
    const doorAppreciation = selectedProduct?.appreciationPercentage || 0;
    if (doorAppreciation > 0) {
      const incVal = Math.round(doorPriceWithAssembly * (doorAppreciation / 100));
      doorPriceWithAssembly += incVal;
    }

    // 6) Применяем локальную скидку на дверь
    const doorDiscount = selectedProduct?.orderDiscount || 0;
    if (doorDiscount > 0) {
      const discVal = Math.round(doorPriceWithAssembly * (doorDiscount / 100));
      doorPriceWithAssembly -= discVal;
    }

    // 7) Считаем сумму аксессуаров (каждый со своей скидкой/надбавкой)
    let accessoriesSum = 0;
    if (isDoor(selectedProduct)) {
      if (selectedProduct.doorKnob) {
        accessoriesSum += calcAccessoryFinalPrice(selectedProduct.doorKnob, amount);
      }
      if (selectedProduct.doorstep) {
        accessoriesSum += calcAccessoryFinalPrice(selectedProduct.doorstep, amount);
      }
      if (selectedProduct.doorPanel) {
        accessoriesSum += calcAccessoryFinalPrice(selectedProduct.doorPanel, amount);
      }
      if (selectedProduct.latch) {
        accessoriesSum += calcAccessoryFinalPrice(selectedProduct.latch, amount);
      }
    }

    // 8) Складываем дверь + аксессуары
    let rowSubtotal = doorPriceWithAssembly + accessoriesSum;

    // 9) Применяем "глобальное удорожание" (increasePrice)
    if (increasePrice > 0) {
      const globalInc = Math.round(rowSubtotal * (increasePrice / 100));
      rowSubtotal += globalInc;
    }

    // Итог по данной двери
    const priceWithDiscount = rowSubtotal;

    // Формируем структуру "строки" для таблицы
    const data = {
      delete: (
        <div
          className="cursor-pointer text-danger"
          onClick={() => {
            deleteSelectedProduct(i++);
          }}
        >
          <CloseOutlined />
        </div>
      ),
      key: selectedProduct.uuid || selectedProduct._id,
      number,
      productName: selectedProduct?.name || product?.name,
      unit: selectedProduct?.unit || product?.productType?.unit,
      amount: (
        <InputNumber
          style={{ width: 50 }}
          className="me-1"
          value={amount || 0}
          min={0}
          onChange={(value) => {
            if (value !== null && value !== undefined) {
              saveAmount(selectedProduct, parseInt(value.toString()));
            }
          }}
        />
      ),
      price: <div style={{ inlineSize: 100 }}>{currencyFormat(doorBasePrice)}</div>,
      description: (
        <Input.TextArea
          style={{ width: 200 }}
          defaultValue={selectedProduct?.description || selectedProduct?.comment || ""}
          onBlur={(e) => {
            saveDescription(selectedProduct, e.target.value);
          }}
        />
      ),
      addPetli: isDoor(selectedProduct) ? selectedProduct?.addPetli : "-",
      assembly: isDoor(selectedProduct) ? (selectedProduct?.assembly ? "есть" : "нет") : "-",
      doorKnob: isDoor(selectedProduct) ? selectedProduct?.doorKnob?.name : "-",
      doorPanel: isDoor(selectedProduct) ? selectedProduct?.doorPanel?.name : "-",
      latchName: isDoor(selectedProduct) ? selectedProduct?.latch?.name : "-",
      doorstepName: isDoor(selectedProduct) ? selectedProduct?.doorstep?.name : "-",
      opening: isDoor(selectedProduct) ? selectedProduct?.doorHandle : "-",
      size: getSize(selectedProduct),
      aluminium: (() => {
        if (!selectedProduct.aluminium) return "-";
        switch (selectedProduct.aluminium) {
          case "GOLD":
            return "Золото";
          case "BLACK":
            return "Черный";
          case "SILVER":
            return "Серебро";
          case "WITHOUT_ALUMINIUM":
            return "-";
          default:
            return selectedProduct.aluminium;
        }
      })(),
      orderDiscountNode: (
        <InputNumber
          style={{ width: 50 }}
          className="me-1"
          value={doorDiscount}
          min={0}
          max={100}
          onChange={(val) => {
            if (val !== null && val !== undefined) {
              saveDiscount(selectedProduct, parseInt(val.toString()));
            }
          }}
        />
      ),
      appreciationPercentage: (
        <InputNumber
          style={{ width: 75 }}
          className="me-1"
          value={doorAppreciation}
          min={0}
          max={100}
          onChange={(val) => {
            if (val !== null && val !== undefined) {
              saveAppreciationPercentage(selectedProduct, parseInt(val.toString()));
            }
          }}
        />
      ),
      priceWithDiscount,
      accessories: buildAccessoryRows(selectedProduct, amount),
    };

    dataSource.push(data);
    number++;
  }

  /**
   * Функция для определения размеров (если продукт "дверь")
   */
  function getSize(selectedProduct) {
    if (selectedProduct.width && selectedProduct.height) {
      return `${selectedProduct.height}x${selectedProduct.width}`;
    }
    return "-";
  }

  /**
   * Подготавливаем вложенные ряды под-таблицы "Аксессуары"
   * (чтобы отображать каждую ручку/порог и т.п. отдельной строкой).
   */
  function buildAccessoryRows(selectedProduct, amountForAcc) {
    if (!isDoor(selectedProduct)) {
      return [];
    }

    const accessories = [];

    function pushAccessory(acc) {
      if (!acc) return;
      // Считаем финальную цену аксессуара тем же методом, что и выше
      const finalPrice = calcAccessoryFinalPrice(acc, amountForAcc);

      accessories.push({
        key: acc._id || acc.name,
        label: acc.name,
        product: {
          ...acc,
          price: acc.price || 0,
        },
        discount: acc.accessoryDiscount || 0,
        appreciation: acc.accessoryAppreciation || 0,
        finalPrice,
      });
    }

    pushAccessory(selectedProduct.doorKnob);
    pushAccessory(selectedProduct.latch);
    pushAccessory(selectedProduct.doorstep);
    pushAccessory(selectedProduct.doorPanel);

    return accessories;
  }

  // -- Функции-хелперы для записи изменений в selectedProducts:

  const isSameProduct = (selectedProduct, product) => {
    const selectedProductId = selectedProduct?.product?._id || selectedProduct?._id;
    const productId = product?.product?._id || product?._id;

    if (
      productId === selectedProductId &&
      product.width === selectedProduct.width &&
      product.height === selectedProduct.height &&
      product.name === selectedProduct.name &&
      product.aluminium === selectedProduct.aluminium &&
      product.doorHandle === selectedProduct.doorHandle
    ) {
      return true;
    }
    return false;
  };

  const saveDiscount = (selectedProd, value) => {
    const newList = [];
    for (let p of selectedProducts) {
      if (isSameProduct(selectedProd, p)) {
        p.orderDiscount = value;
      }
      newList.push(p);
    }
    setSelectedProducts(newList);
  };

  const saveAppreciationPercentage = (selectedProd, value) => {
    const newList = [];
    for (let p of selectedProducts) {
      if (isSameProduct(selectedProd, p)) {
        p.appreciationPercentage = value;
      }
      newList.push(p);
    }
    setSelectedProducts(newList);
  };

  const saveAmount = (selectedProd, value) => {
    const newList = [];
    for (let p of selectedProducts) {
      if (isSameProduct(selectedProd, p)) {
        p.amount = value;
      }
      newList.push(p);
    }
    setSelectedProducts(newList);
  };

  const saveDescription = (selectedProd, value) => {
    const newList = [];
    for (let p of selectedProducts) {
      if (isSameProduct(selectedProd, p)) {
        p.comment = value;
      }
      newList.push(p);
    }
    setSelectedProducts(newList);
  };

  return dataSource;
};
