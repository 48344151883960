import LeadPopover from "../components/LeadPopover";
import config from "../../../config.json";
import {
  currencyFormat,
  getOrderPriceWithDiscountAndAppreciationPercentage,
} from "../../NewLead/utils/price";
import { EditOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { SpecificationMenuPopover } from "../components";

export const clientLeadsColumns = [
  {
    title: "",
    dataIndex: "numeration",
    key: "numeration",
  },
  {
    title: "Дата создания сделки",
    dataIndex: "createdDate",
    key: "createdDate",
  },
  {
    title: "Номер договора",
    dataIndex: "agreementNumber",
    key: "agreementNumber",
  },
  {
    title: "Кол-во дверей",
    dataIndex: "doorsAmount",
    key: "doorsAmount",
  },
  {
    title: "Цвет алюминия",
    dataIndex: "aluminium",
    key: "aluminium",
  },
  {
    title: "Ответственный",
    dataIndex: "responsible",
    key: "responsible",
  },
  {
    title: "Сумма",
    dataIndex: "totalPrice",
    key: "totalPrice",
  },
  {
    title: "Оценка NPS",
    dataIndex: "npsScore",
    key: "npsScore",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
  },
];

export const clientLeadsDataSource = (
  leads,
  setLeadFullInfo,
  setShowLeadFullInfo
) => {
  const dataSource = [];

  const getSpecificationData = (specifications = []) => {
    let doorsCount = 0;
    let totalSum = 0;

    for (let specification of specifications) {
      doorsCount += specification?.doorCount || 0;
      totalSum += specification?.totalPrice || 0;
    }

    return {
      doorsCount,
      totalSum,
    };
  };

  let i = 1;
  for (let lead of leads) {
    const data = getSpecificationData(lead.applications);
    let color = "";
    if (lead.aluminium.length) {
      if (lead.aluminium.length === 1) {
        color = lead.aluminium[0];
      } else {
        color = `${lead.aluminium[0]}, ${lead.aluminium[1]}`;
      }
    }

    dataSource.push({
      key: lead._id,
      numeration: i++,
      createdDate: new Date(lead.createdAt).toLocaleDateString(),
      responsible: `${lead?.responsibleUserId?.name} ${lead?.responsibleUserId?.surname}`,
      agreementNumber: (
        <a
          href={`${config.service}/api/file/agreement/${lead._id}`}
          target="_blank"
        >
          {lead.agreementNumber}
        </a>
      ),
      actions: (
        <LeadPopover
          data={lead}
          setLeadFullInfo={setLeadFullInfo}
          setShowLeadFullInfo={setShowLeadFullInfo}
        />
      ),
      data: lead,
      doorsAmount: data.doorsCount,
      aluminium: color,
      totalPrice: currencyFormat(data.totalSum),
      npsScore: lead.npsScore || 'N/A',  // NPS Score добавлено здесь
    });
  }

  return dataSource;
};

export const leadSpecificatinsColumn = [
  {
    title: "№",
    width: 45,
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Дата создания",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 140,
  },
  {
    title: "Дизайнер",
    dataIndex: "designerDiscount",
    key: "designerDiscount",
    width: 65,
  },
  {
    title: "Срок сдачи",
    dataIndex: "deadline",
    key: "deadline",
  },
  {
    title: "Цвет",
    dataIndex: "aluminium",
    key: "aluminium",
  },
  {
    title: "Кол-во дверей",
    dataIndex: "doorsCount",
    key: "doorsCount",
  },
  {
    title: "Сумма",
    dataIndex: "totalPrice",
    key: "totalPrice",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: 75,
    fixed: "right",
  },
];

export const getLeadSpecificationsDataSource = (
  specifications = [],
  clientId,
  openLead = () => {}
) => {
  const data = [];

  let i = 1;
  for (let specification of specifications) {
    data.push({
      key: specification._id,
      number: i++,
      createdAt: new Date(specification.createdAt).toLocaleDateString(),
      designerDiscount: specification.designerDiscount > 0 ? "Есть" : "Нет",
      data: specification,
      totalPrice: currencyFormat(specification.totalPrice),
      doorsCount: specification.doorCount,
      aluminium: specification.aluminium,
      deadline: new Date(specification.deadline).toLocaleDateString(),
      actions: (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              padding: "0px 5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 5,
            }}
            onClick={() => {
              openLead(clientId, specification.lead, specification._id);
            }}
          >
            <EditOutlined />
          </Button>
          <SpecificationMenuPopover data={specification} />
        </div>
      ),
    });
  }

  return data;
};

export const specificationProductsColumn = [
  {
    title: "№",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Наименование товара",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Размеры",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Открывание",
    dataIndex: "opening",
    key: "opening",
  },
  {
    title: "Алюминий",
    dataIndex: "aluminium",
    key: "aluminium",
  },
  {
    title: "Кол - во",
    dataIndex: "amount",
    key: "amount",
    width: 75,
  },
  {
    title: "Стоимость",
    dataIndex: "price",
    key: "price",
  },
];

export const getSpecificationProductsDataSource = (products = []) => {
  if (!Array.isArray(products)) {
    return [];
  }

  const dataSource = [];
  let i = 1;

  const getAluminium = (aluminium) => {
    if (aluminium === "BLACK") {
      return "черный";
    } else if (aluminium === "SILVER") {
      return "серебро";
    } else if (aluminium === "GOLD") {
      return "золото";
    } else if (aluminium === "WITHOUT_ALUMINIUM") {
      return "без алюминия";
    }

    return "-";
  };

  for (let product of products) {
    if (product.product) {
      let price = product.product.price;
    if (product.aluminium === "BLACK")  {
        price *= 1;
      }
    if (product.aluminium === "GOLD") {
        price *= 1.1;
      }

    const orderDiscount = product?.orderDiscount || 0;
    const appreciationPercentage = product?.appreciationPercentage || 0;

    price = getOrderPriceWithDiscountAndAppreciationPercentage(
      price,
      orderDiscount,
      appreciationPercentage
    );

    price = price * product.amount;

    price = parseInt(price);

    dataSource.push({
      number: i++,
      name: product?.product?.name,
      unit: "",
      amount: product.amount,
      price: currencyFormat(price),
      size:
        product.height && product.width
          ? `${product.height}x${product.width}`
          : "-",
      opening: product.doorHandle || "-",
      aluminium: getAluminium(product.aluminium),
    });
    }else{
      dataSource.push({
        number: i++,
        name: product.name,
        unit: "",
        amount: product.amount,
        price: currencyFormat(product.name === 'Сборка' ? product.price :product.price * product.amount),
        size: "-",
        opening: "-",
        aluminium: "-",
      });
    }
    
  }

  return dataSource;
};
