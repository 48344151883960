import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  TreeSelect,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import { getTreeNode } from "../utils/format";

// Вместо uuidv4 можно использовать crypto.randomUUID(), но не забудьте о поддержке браузеров.
// Здесь для надёжности используем uuidv4 из "uuid".
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

const SelectProduct = ({
  selectedProducts,
  setSelectedProducts,
  isLoading = false,
  products = [],
  form,
  knobs = [],
  doorstep = [],
  latch = [],
  doorPanel = [],
}) => {
  // Локальные значения
  const [values, setValues] = useState({
    amount: 1,
    addPetli: 0,
    assembly: false,
  });

  const [selectedProduct, setSelectedProduct] = useState({});

  // Проверка: является ли выбранный товар "дверью" (по наличию ограничений высоты/ширины)
  const isSelectedProductDoor = () => {
    return (
      selectedProduct?.maxHeight ||
      selectedProduct?.minHeight ||
      selectedProduct?.maxWidth ||
      selectedProduct?.minWidth
    )
      ? true
      : false;
  };

  // Сброс значений для не-двери
  const resetValues = () => {
    if (!isSelectedProductDoor()) {
      form.setFieldValue("width", "");
      form.setFieldValue("height", "");
      form.setFieldValue("opening", "");
      form.setFieldValue("aluminium", "");
      form.setFieldValue("addPetli", 0);
      form.setFieldValue("assembly", false);
      form.setFieldValue("description", "");
      form.setFieldValue("doorHandle", "");
      form.setFieldValue("doorKnob", "");
      form.setFieldValue("doorstep", "");
      form.setFieldValue("doorPanel", "");
      form.setFieldValue("latch", "");

      setValues({
        ...values,
        height: undefined,
        width: undefined,
        opening: undefined,
        aluminium: undefined,
        addPetli: 0,
        assembly: false,
        description: undefined,
        doorHandle: undefined,
        doorKnob: undefined,
        doorstep: undefined,
        doorPanel: undefined,
        latch: undefined,
      });
    }
  };

  // Кнопка "Добавить"
  const addProduct = () => {
    if (!selectedProduct) {
      message.error("Неполная информация о товаре", 2);
      return;
    }

    const amount = values?.amount || form.getFieldValue("amount");

    // Если товар — "дверь", проверим поля
    if (isSelectedProductDoor()) {
      if (
        !values?.opening ||
        !values?.aluminium ||
        !values?.width ||
        !values?.height ||
        !amount
      ) {
        message.error("Неполная информация о товаре", 2);
        return;
      } else {
        resetValues();
      }
    } else {
      // Не дверь — сбрасываем поля дверей
      resetValues();
      // Очистим "лишние" поля
      values.height = undefined;
      values.width = undefined;
      values.opening = undefined;
      values.aluminium = undefined;
      values.addPetli = 0;
      values.assembly = false;
      values.description = undefined;
      values.doorHandle = undefined;
      values.doorKnob = undefined;
      values.doorstep = undefined;
      values.doorPanel = undefined;
      values.latch = undefined;
    }

    // Собираем новый объект
    const newProduct = {
      ...values,
      doorHandle: values.opening,
      name: selectedProduct?.name || "",
      unit: selectedProduct?.productType?.unit || "",
      price: selectedProduct?.price || 0,
      productId: selectedProduct?._id || "",
      product: selectedProduct || "",
      // Генерируем uuid (не пытаемся использовать _id, mongo сама создаст).
      uuid: uuidv4(),
    };

    // Добавляем в выбранные товары
    // (если не хотим суммировать одинаковые — просто push).
    setSelectedProducts((prev) => [...prev, newProduct]);

    message.success("Товар добавлен в список", 1);

    // Сбрасываем поля формы
    form.resetFields([
      "doorKnob",
      "latch",
      "doorstep",
      "doorPanel",
      "description",
      "width",
      "height",
      "opening",
      "aluminium",
    ]);

    setValues({
      amount: 1,
      addPetli: 0,
      assembly: false,
    });
  };

  // Вспомогательная функция: проверить doorType у `selectedProduct`
  const isDoor = () => {
    try {
      return selectedProduct.doorType ? true : false;
    } catch (e) {
      return false;
    }
  };

  // Сохраняем изменения в локальном state
  const handleChange = (key, value) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  // При выборе товара из Select/TreeSelect заполняем поля
  useEffect(() => {
    form.setFieldValue("price", selectedProduct?.price);
    form.setFieldValue("height", selectedProduct?.minHeight);
    form.setFieldValue("width", selectedProduct?.minWidth);

    setValues((prev) => ({
      ...prev,
      price: selectedProduct?.price || 0,
      width: selectedProduct?.minWidth,
      height: selectedProduct?.minHeight,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  return (
    <div>
      <div className="d-flex flex-wrap">
        <Form.Item name={"selectedProduct"} className="w-50 me-5">
          <div>&nbsp;</div>
          {/* 
            (1) Select (можно скрыть, если используем TreeSelect)
            style={{ display: "none" }} 
          */}
          <Select
            placeholder="Выберите товар из списка"
            loading={isLoading}
            onChange={(value) => {
              const foundProduct = products.find((v) => v._id === value);
              if (foundProduct) {
                setSelectedProduct(foundProduct);
              } else {
                setSelectedProduct({});
              }
            }}
            style={{ display: "none" }}
          >
            <Option disabled selected value="">
              Выберите товар из списка
            </Option>
            {products.map((product) => (
              <Option key={product._id} value={product._id}>
                {product.name}
              </Option>
            ))}
          </Select>

          {/* 
            (2) TreeSelect
          */}
          <TreeSelect
            bordered
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            placeholder="Выберите товар из списка"
            showSearch
            treeNodeFilterProp="title"
            onChange={(value) => {
              const foundProduct = products.find((v) => v._id === value);
              if (foundProduct) {
                setSelectedProduct(foundProduct);
              } else {
                setSelectedProduct({});
              }
            }}
          >
            {getTreeNode(products)}
          </TreeSelect>
        </Form.Item>

        {/* Размеры (высота+ширина) */}
        <div style={{ marginRight: 15 }}>
          <div>Размер:</div>
          <div style={{ display: "flex" }}>
            <Form.Item name="height" rules={[{ required: isDoor() }]}>
              <InputNumber
                placeholder="Высота"
                disabled={!isDoor()}
                max={selectedProduct?.maxHeight || 0}
                min={selectedProduct?.minHeight || 0}
                onChange={(value) => handleChange("height", value)}
              />
            </Form.Item>
            <Form.Item name="width" rules={[{ required: isDoor() }]}>
              <InputNumber
                placeholder="Ширина"
                disabled={!isDoor()}
                max={selectedProduct?.maxWidth || 0}
                min={selectedProduct?.minWidth || 0}
                onChange={(value) => handleChange("width", value)}
              />
            </Form.Item>
          </div>
        </div>

        {/* Цена (read-only) */}
        <div style={{ marginRight: 15 }}>
          <div>Цена:</div>
          <Form.Item name="price">
            <InputNumber disabled defaultValue={selectedProduct?.price || 0} />
          </Form.Item>
        </div>

        {/* Количество */}
        <div style={{ marginRight: 15 }}>
          <div>Количество:</div>
          <Form.Item name="amount" rules={[{ required: true }]}>
            <InputNumber
              onChange={(value) => handleChange("amount", value)}
              min={1}
              disabled={!selectedProduct?.name}
              defaultValue=""
            />
          </Form.Item>
        </div>

        {/* Открывание */}
        <div style={{ marginRight: 15 }}>
          <div>Открывание:</div>
          <Form.Item name="opening" rules={[{ required: isDoor() }]}>
            <Select
              onChange={(value) => handleChange("opening", value)}
              defaultValue=""
              disabled={!isDoor() || !selectedProduct?.name}
            >
              <Option value="">Выберите открывание</Option>
              <Option value="Наружное правое">Наружное правое</Option>
              <Option value="Наружное левое">Наружное левое</Option>
              <Option value="Внутреннее правое">Внутреннее правое</Option>
              <Option value="Внутреннее левое">Внутреннее левое</Option>
              <Option value="Без открывания">Без открывания</Option>
            </Select>
          </Form.Item>
        </div>

        {/* Алюминий */}
        <div style={{ marginRight: 15 }}>
          <div>Алюминий:</div>
          <Form.Item name="aluminium" rules={[{ required: isDoor() }]}>
            <Select
              onChange={(value) => handleChange("aluminium", value)}
              defaultValue=""
              disabled={!isDoor() || !selectedProduct?.name}
            >
              <Option value="">Выберите алюминий</Option>
              <Option value="SILVER">Серебро</Option>
              <Option value="BLACK">Чёрный</Option>
              <Option value="GOLD">Золото</Option>
              <Option value="WITHOUT_ALUMINIUM">Без алюминия</Option>
            </Select>
          </Form.Item>
        </div>
      </div>

      {/* Ручка, Поворотник, Порог, Добор */}
      <div className="d-flex flex-wrap">
        {/* Ручка */}
        <div style={{ marginRight: 15, width: "40%" }}>
          <div>Выберите ручку:</div>
          <Form.Item name="doorKnob">
            <Select
              defaultValue=""
              disabled={!isDoor() || !selectedProduct?.name}
              onChange={(value) => {
                const foundProduct = knobs.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("doorKnob", foundProduct);
                }
              }}
            >
              <Option value="">Пожалуйста выберите ручку</Option>
              {knobs.map((knob) => (
                <Option key={knob._id} value={knob._id} label={knob.name}>
                  {knob.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        {/* Поворотник */}
        <div style={{ marginRight: 15, width: "40%" }}>
          <div>Поворотник:</div>
          <Form.Item name="latch" rules={[{ required: isDoor() }]}>
            <Select
              defaultValue=""
              disabled={!isDoor() || !selectedProduct?.name}
              onChange={(value) => {
                const foundProduct = latch.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("latch", foundProduct);
                }
              }}
            >
              <Option value="">Не выбрано</Option>
              {latch.map((knob) => (
                <Option key={knob._id} value={knob._id} label={knob.name}>
                  {knob.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="d-flex flex-wrap">
        {/* Порог */}
        <div style={{ marginRight: 15, width: "40%" }}>
          <div>Порог</div>
          <Form.Item name="doorstep" rules={[{ required: isDoor() }]}>
            <Select
              defaultValue=""
              disabled={!isDoor() || !selectedProduct?.name}
              onChange={(value) => {
                const foundProduct = doorstep.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("doorstep", foundProduct);
                }
              }}
            >
              <Option value="">Выберите порог</Option>
              {doorstep.map((k) => (
                <Option key={k._id} value={k._id} label={k.name}>
                  {k.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        {/* Добор */}
        <div style={{ marginRight: 15, width: "40%" }}>
          <div>Добор</div>
          <Form.Item name="doorPanel" rules={[{ required: isDoor() }]}>
            <Select
              defaultValue=""
              disabled={!isDoor() || !selectedProduct?.name}
              onChange={(value) => {
                const foundProduct = doorPanel.find((v) => v._id === value);
                if (foundProduct) {
                  handleChange("doorPanel", foundProduct);
                }
              }}
            >
              <Option value="">Выберите добор</Option>
              {doorPanel.map((panel) => (
                <Option key={panel._id} value={panel._id} label={panel.name}>
                  {panel.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="d-flex flex-wrap mb-3">
        {/* Сборка */}
        <div
          style={{
            marginRight: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>Сборка</div>
          <Form.Item name="assembly">
            <Checkbox
              disabled={!isDoor() || !selectedProduct?.name}
              onChange={(event) => {
                handleChange("assembly", event.target.checked);
              }}
            />
          </Form.Item>
        </div>

        {/* Доп петля */}
        <div style={{ marginRight: 15 }}>
          <div>Доп петля</div>
          <Form.Item name="addPetli" rules={[{ required: isDoor() }]}>
            <InputNumber
              disabled={!isDoor() || !selectedProduct?.name}
              min={0}
              max={100}
              defaultValue={0}
              name="addPetli"
              placeholder="Доп петли"
              onChange={(val) => {
                handleChange("addPetli", val);
              }}
            />
          </Form.Item>
        </div>
      </div>

      <div
        className="d-flex flex-wrap"
        style={{
          marginBottom: 30,
          display: "flex",
        }}
      >
        {/* Примечание */}
        <Form.Item name="description" style={{ marginRight: 15 }}>
          <div>Примечание</div>
          <Input
            onChange={(event) => handleChange("description", event.target.value)}
          />
        </Form.Item>

        {/* Кнопка "Добавить" */}
        <div>
          <div>&nbsp;</div>
          <Form.Item>
            <Button
              disabled={!selectedProduct?.name}
              type="primary"
              onClick={addProduct}
            >
              Добавить
            </Button>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default SelectProduct;
