import React, { useEffect, useState } from "react";
import { Row, Col, Card, DatePicker, Button, Table, Radio, Space, message, Spin } from "antd";
import moment from "moment";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  
} from "recharts";

import { fetchDashboardData } from "./utils/dashboardFetch";

const { RangePicker } = DatePicker;
const pieColors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

/**
 * Преобразует данные менеджеров из формата, возвращаемого бекендом,
 * в формат, подходящий для таблицы и нижней круговой диаграммы.
 * Исходный формат:
 * {
 *   managerId: ObjectId,
 *   managerName: "Фамилия Имя",
 *   documents: [
 *     { doc_type: "agreement", count: X },
 *     { doc_type: "commercial-proposal", count: Y }
 *   ]
 * }
 * Преобразованный формат:
 * { manager: "Фамилия Имя", agreements: X, kp: Y }
 */
function transformManagers(rawManagers) {
  return rawManagers.map((item) => {
    let agreementsCount = 0;
    let kpCount = 0;
    if (item.documents && Array.isArray(item.documents)) {
      item.documents.forEach((doc) => {
        if (doc.doc_type === "agreement") {
          agreementsCount = doc.count;
        } else if (doc.doc_type === "commercial-proposal") {
          kpCount = doc.count;
        }
      });
    }
    return {
      manager: item.managerName,
      agreements: agreementsCount,
      kp: kpCount,
    };
  });
}

function Dashboard() {
  // Состояния для выбора диапазона дат
  const [dateRange, setDateRange] = useState([moment().subtract(7, "days"), moment()]);
  const [loading, setLoading] = useState(false);

  // Состояния для данных дешборда (для графиков)
  const [requestsData, setRequestsData] = useState([]);        // Для графика "Количество клиентов"
  const [managerData, setManagerData] = useState([]);          // Для графика "Количество договоров"
  const [overallData, setOverallData] = useState([]);          // Для графика "Количество КП"
  const [distributionData, setDistributionData] = useState([]); // Дополнительные данные (если появятся)
  const [durationData, setDurationData] = useState([]);         // Дополнительные данные (если появятся)
  const [managers, setManagers] = useState([]);                 // Для таблицы и нижней диаграммы по менеджерам

  // Состояния для карточек с количеством клиентов
  const [clientsToday, setClientsToday] = useState(0);
  const [clientsMonth, setClientsMonth] = useState(0);

  // Переключатель для нижней круговой диаграммы (agreements / kp)
  const [pieMode, setPieMode] = useState("agreements");

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const start = dateRange[0].format("YYYY-MM-DD");
        const end = dateRange[1].format("YYYY-MM-DD");

        const data = await fetchDashboardData({ start, end });

        // Устанавливаем полученные данные
        setRequestsData(data.requestsData || []);
        setManagerData(data.managerData || []); // для договоров (LineChart)
        setOverallData(data.overallData || []); // для КП (LineChart)
        setDistributionData(data.distributionData || []);
        setDurationData(data.durationData || []);
        setClientsToday(data.clientsToday || 0);
        setClientsMonth(data.clientsMonth || 0);
        // Преобразуем данные менеджеров для таблицы и нижней диаграммы
        const transformedManagers = transformManagers(data.managers || []);
        setManagers(transformedManagers);
      } catch (error) {
        console.error("Dashboard fetch error:", error);
        message.error("Не удалось загрузить статистику");
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, [dateRange]);

  const onDateChange = (dates) => {
    setDateRange(dates);
  };

  const setQuickRange = (type) => {
    switch (type) {
      case "today":
        setDateRange([moment(), moment()]);
        break;
      case "yesterday":
        setDateRange([moment().subtract(1, "days"), moment().subtract(1, "days")]);
        break;
      case "7days":
        setDateRange([moment().subtract(7, "days"), moment()]);
        break;
      case "30days":
        setDateRange([moment().subtract(30, "days"), moment()]);
        break;
      case "month":
        setDateRange([moment().startOf("month"), moment().endOf("month")]);
        break;
      default:
        break;
    }
  };

  // Таблица "Заявки по менеджерам"
  const managerColumns = [
    { title: "Менеджер", dataIndex: "manager", key: "manager" },
    { title: "Отправленные договоры", dataIndex: "agreements", key: "agreements" },
    { title: "Отправленные КП", dataIndex: "kp", key: "kp" },
  ];

  // Нижняя круговая диаграмма: если pieMode === "agreements", используем m.agreements; иначе m.kp
  const bottomPieData = managers.map((m) => ({
    name: m.manager,
    value: pieMode === "agreements" ? m.agreements : m.kp,
  }));

  // Для PieChart "Общее распределение" вычисляем суммарные значения за выбранный период
  const totalClientsCount = requestsData.reduce((sum, item) => sum + item.value, 0);
  const totalAgreementsCount = managerData.reduce((sum, item) => sum + item.value, 0);
  const totalKPCount = overallData.reduce((sum, item) => sum + item.value, 0);
  const pieDistributionData = [
    { name: "Клиенты", value: totalClientsCount },
    { name: "Договоры", value: totalAgreementsCount },
    { name: "КП", value: totalKPCount },
  ];

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: 16 }}>
      {/* Заголовок и выбор дат */}
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col flex="auto">
          <h2 style={{ margin: 0 }}>Дешборд</h2>
        </Col>
        <Col>
          <Space>
            <RangePicker
              value={dateRange}
              onChange={onDateChange}
              format="DD MMM, YYYY"
            />
            <Button onClick={() => setQuickRange("today")}>Сегодня</Button>
            <Button onClick={() => setQuickRange("yesterday")}>Вчера</Button>
            <Button onClick={() => setQuickRange("7days")}>7 дней</Button>
            <Button onClick={() => setQuickRange("30days")}>30 дней</Button>
            <Button onClick={() => setQuickRange("month")}>Месяц</Button>
          </Space>
        </Col>
      </Row>

      {/* Карточки с количеством клиентов */}
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card title="Клиентов сегодня">
            <h2>{clientsToday}</h2>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Клиентов за месяц">
            <h2>{clientsMonth}</h2>
          </Card>
        </Col>
      {/* <Col span={6}>
          <Card title="Средняя продолжительность сборки">
            <h2>{durationData}</h2>
          </Card>
        </Col> */}
      </Row>

      {/* Верхний ряд: 3 графика (LineCharts) */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        {/* 1) Количество клиентов */}
        <Col xs={24} md={8}>
          <Card title="Количество клиентов" size="small">
            <ResponsiveContainer width="100%" height={220}>
              <LineChart data={requestsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#1890ff" name="Клиенты" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        {/* 2) Количество договоров */}
        <Col xs={24} md={8}>
          <Card title="Количество договоров" size="small">
            <ResponsiveContainer width="100%" height={220}>
              <LineChart data={managerData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend />
                {managerData && managerData.length > 0 ? (
                  <Line type="monotone" dataKey="value" stroke="#c41d7f" name="Договоры" />
                ) : (
                  <text x="50%" y="50%" textAnchor="middle" fill="#999">
                    Нет данных
                  </text>
                )}
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        {/* 3) Количество КП */}
        <Col xs={24} md={8}>
          <Card title="Количество КП" size="small">
            <ResponsiveContainer width="100%" height={220}>
              <LineChart data={overallData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Legend />
                {overallData && overallData.length > 0 ? (
                  <Line type="monotone" dataKey="value" stroke="#2f54eb" name="КП" />
                ) : (
                  <text x="50%" y="50%" textAnchor="middle" fill="#999">
                    Нет данных
                  </text>
                )}
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>

      {/* Средний ряд: 2 круговые диаграммы рядом */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} md={12}>
          <Card title="Общее распределение" size="small">
            <ResponsiveContainer width="100%" height={220}>
              <PieChart>
                <Pie
                  data={pieDistributionData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={70}
                  label
                >
                  {pieDistributionData.map((entry, index) => (
                    <Cell
                      key={`dist-cell-${index}`}
                      fill={pieColors[index % pieColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Card
            title="Распределение по менеджерам"
            size="small"
          >
            <ResponsiveContainer width="100%" height={220}>
              <PieChart>
                <Pie
                  data={bottomPieData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={70}
                  label
                >
                  {bottomPieData.map((entry, index) => (
                    <Cell
                      key={`mgr-cell-${index}`}
                      fill={pieColors[index % pieColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>

      {/* Нижний ряд: Таблица менеджеров и круговая диаграмма по менеджерам */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xs={24} md={12}>
          <Card
            title="Заявки по менеджерам"
            size="small"
            extra={
              <Radio.Group value={pieMode} onChange={(e) => setPieMode(e.target.value)}>
              </Radio.Group>
            }
          >
            <Table columns={managerColumns} dataSource={managers} pagination={false} rowKey="manager" />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
