import React, { useEffect, useState } from "react";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
// import Users from "./components/Users/User";
import Dashboard from "./pages/Dashboard/Dashboard";
import { LoginForm } from "./pages/Login";
import { getToken } from "./utils/token";
import { ClientsPage } from "./pages/Clients";
import { ClientInfo } from "./pages/ClientInfo";
import { EditLeadPage, NewLeadPage, NewSpecification } from "./pages/NewLead";
import { ConfigProvider, Layout } from "antd";
import { ProductsPage } from "./pages/Products";
import { OrderStatusPage } from "./pages/OrderStatus";
import { UsersPage } from "./pages/Users";
import { CompanyPage } from "./pages/Company";
import ruRU from "antd/es/locale/ru_RU";
import { fetchUserPermission } from "./components/Sidebar/utils/fetch";
import Supplies from "./pages/Supplies/Supplies";
import { EntityPage } from "./pages/KeplerEntity";
import { KeplerPartners } from "./pages/KeplerPartners";
import { AssemblyStatus } from "./pages/AssemblyStatus";
import { DeliveryStatus } from "./pages/DeliveryStatus";
import { ManufactureStatus } from "./pages/ManufactureStatus";
import InstallationStatus from "./pages/InstallationStatus/InstallationStatus";
import NPSPage from "./pages/NPS";
import DistributionPage from "./pages/Distribution/DistributionPage";
function App() {
  const [token, setToken] = useState(getToken());
  const [userPermission, setUserPermission] = useState({});

  useEffect(() => {
    if (token) {
      fetchUserPermission(setUserPermission);
    }
  }, [token]);

  if (!token) {
    return <LoginForm setToken={setToken} />;
  }

  const getDefaultRoute = (userPermission) => {
    if (userPermission.manufactureStatus && !userPermission.orserStatus) {
      return <ManufactureStatus userPermission={userPermission} />
    }
    if (userPermission.assemblyStatus && !userPermission.orserStatus) {
      return <AssemblyStatus userPermission={userPermission} />
    }
    if (userPermission.installationStatus && !userPermission.orserStatus) {
      return <InstallationStatus userPermission={userPermission} />
    }
    if (userPermission.distribution && !userPermission.distribution) {
      return <DistributionPage userPermission={userPermission} />
    }
    if (userPermission.deliveryStatus && !userPermission.orserStatus) {
      return <DeliveryStatus userPermission={userPermission} />
    } else return <NewLeadPage />;
  };

  return (
    <ConfigProvider locale={ruRU}>
  <Router>
    <Layout>
      <Sidebar userPermission={userPermission} />
      <Layout
        style={{
          padding: 15,
          minHeight: "100vh",
          minWidth: 700,
        }}
      >
        <Routes>
          <Route path="/client">
            <Route
              path=":clientId"
              element={
                <ClientInfo
                  setToken={setToken}
                  userPermission={userPermission}
                />
              }
            />
            <Route index element={<ClientsPage />} />
          </Route>

          <Route path="/product" element={<ProductsPage setToken={setToken} />} />

          <Route path="/status">
            <Route
              index
              element={<OrderStatusPage userPermission={userPermission} />}
            />
          </Route>

          <Route path="/kepler-partner">
            <Route index element={<KeplerPartners />} />
          </Route>

          <Route path="/lead">
            <Route path=":clientId">
              <Route path=":leadId">
                <Route path=":applicationId">
                  <Route
                    path="edit"
                    element={<EditLeadPage setToken={setToken} />}
                  />
                </Route>
                <Route path="create" element={<NewSpecification />} />
              </Route>
              <Route index element={<NewLeadPage />} />
            </Route>
            <Route index element={<NewLeadPage />} />
          </Route>

          <Route path="/user">
            <Route
              path=":companyId"
              element={
                <UsersPage
                  setToken={setToken}
                  userPermission={userPermission}
                />
              }
            />
            <Route
              index
              element={
                <CompanyPage
                  setToken={setToken}
                  userPermission={userPermission}
                />
              }
            />
          </Route>

          <Route path="/supplies">
            <Route index element={<Supplies />} />
          </Route>

          <Route path="/entity">
            <Route index element={<EntityPage userPermission={userPermission} />} />
          </Route>

          <Route path="/assembly_status">
            <Route index element={<AssemblyStatus userPermission={userPermission} />} />
          </Route>

          <Route path="/manufacture_status">
            <Route index element={<ManufactureStatus userPermission={userPermission} />} />
          </Route>

          <Route path="/installation_status">
            <Route index element={<InstallationStatus userPermission={userPermission} />} />
          </Route>

          <Route path="/delivery_status">
            <Route index element={<DeliveryStatus userPermission={userPermission} />} />
          </Route>

          {/* Страница Distribution */}
          <Route
            path="/distribution"
            element={<DistributionPage userPermission={userPermission} />}
          />

          <Route path="/dashboard" element={<Dashboard userPermission={userPermission} />} />

          {/* Путь NPS */}
          <Route path="/nps" element={<NPSPage />} />

          {/* Индексный маршрут: если не совпало ничего - делаем редирект или отображаем по умолчанию */}
          <Route index element={getDefaultRoute(userPermission)} />
        </Routes>
      </Layout>
    </Layout>
  </Router>
</ConfigProvider>

  );
}

export default App;
