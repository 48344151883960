export const groupOrderAccessories = (accessories) => {
  const groupedAccessories = {};
  accessories.forEach((accessory) => {
    const key = `${accessory.name || accessory.product.name}:${accessory.price || accessory.product.price}`;

    if (!groupOrderAccessories[key]) {
      groupedAccessories[key] = {
        name: accessory?.name || accessory?.product?.name,
        price: accessory?.price || accessory?.product?.price,
        costPrice: accessory?.costPrice || accessory?.product?.costPrice,
        productType: accessory?.productType || accessory?.product?.productType,
        amount: accessory?.amount || accessory?.product?.amount,
      };
    } else {
      groupedAccessories[key].amount +=
        accessory?.amount || accessory?.product?.amount;
    }
  });

  const accessoriesArray = Object.values(groupedAccessories);
  return accessoriesArray;
};

/**
 * Расчёт стоимости сборки дверей на основании петель и т.д.
 */
export const calculateAssembly = (orders) => {
  let total = 0;
  let assembly = 0;

  // Объект, который вернём наружу (если нужно отобразить сборку отдельной строкой)
  let assemblyOrder = {
    name: "Сборка",
    price: 0,
    amount: 0,
  };

  for (const order of orders) {
    // Если нет doorType или сборка отключена явно, пропускаем
    if (!order?.product?.doorType || order?.assembly === false) {
      continue;
    }

    const petli = calculateDoorHinges({
      height: order.height,
      width: order.width,
      doorCovering: order.product.doorCovering,
      addPetli: order.product.addPetli,
    });

    // Базовая логика по количеству петель
    if (petli === 2) {
      assembly = 12000;
    } 
    if (petli > 2) {
      assembly = 10000 + (petli - 2) * 5000;
    }

    // Добавляем к общей сумме сборки
    total += assembly;

    // Если есть addPetli, добавляем стоимость
    if (order.addPetli && petli === 2) {
      // (order.addPetli * 5000) - 2000 — как у вас в коде
      total += (order.addPetli * 5000) - 2000;
    }
    if (order.addPetli && petli > 2) {
      total += order.addPetli * 5000;
    }

    // Если в заказе сборка не отключена (assembly === true/undefined),
    // то считаем, сколько «дверей» мы собираем
    if (order.assembly !== false) {
      assemblyOrder.amount += (order.amount || 1);
    }
  }

  // Итоговая сумма
  assemblyOrder.price = total;
  return assemblyOrder;
};

/**
 * Считаем, сколько петель нужно для двери
 */
const calculateDoorHinges = ({ height, width, doorCovering, addPetli }) => {
  let petli = 0;

  if (doorCovering === "MIRROR") {
    if (height <= 2299) {
      petli += 3;
    } else {
      petli += 4;
    }
  } else {
    if (width < 900) {
      if (height <= 2249) {
        petli += 2;
      } else if (height > 2249 && height <= 2549) {
        petli += 3;
      } else {
        petli += 4;
      }
    } else {
      if (height <= 2250) {
        petli += 3;
      } else {
        petli += 4;
      }
    }
  }

  return petli;
};
